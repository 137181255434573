import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authReducer from './features/authSlice';
// eslint-disable-next-line import/no-named-as-default
import matchesSlice from './features/matchesSlice';
// eslint-disable-next-line import/no-named-as-default
import matchesIdpaSlice from './features/idpa/matchesIdpaSlice';
import { userMatches } from './api/userMatches';
import { userCompare } from './api/userCompare';

export function makeStore() {
  return configureStore({
    reducer: {
      auth: authReducer,
      matches: matchesSlice,
      matchesIdpa: matchesIdpaSlice,
      [userMatches.reducerPath]: userMatches.reducer,
      [userCompare.reducerPath]: userCompare.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(userMatches.middleware).concat(userCompare.middleware),
  });
}

const store = makeStore();
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
