import type { UserMatches } from '../../pages/api/userMatches';
import type { TimeHFPageState, TimeHFPageStateList } from '../features/matchesSlice';

type TypeCollectionTimeSingle = {
  time: number[];
  hf: number[];
};

type TypeCollectionTime = {
  short: TypeCollectionTimeSingle;
  medium: TypeCollectionTimeSingle;
  long: TypeCollectionTimeSingle;
};

export default (payload: UserMatches): TimeHFPageState => {
  const totalCount: TypeCollectionTime = {
    short: {
      time: [],
      hf: [],
    },
    medium: {
      time: [],
      hf: [],
    },
    long: {
      time: [],
      hf: [],
    },
  };
  const yearsSum: Record<number, TypeCollectionTime> = {};
  const list: TimeHFPageStateList = {};
  Object.keys(payload)
    .filter((year) => Object.entries(payload[+year]).length > 0)
    .forEach((year: string) => {
      const matches = payload[+year];
      if (!yearsSum[+year]) {
        yearsSum[+year] = {
          short: {
            time: [],
            hf: [],
          },
          medium: {
            time: [],
            hf: [],
          },
          long: {
            time: [],
            hf: [],
          },
        };
        list[+year] = {};
      }
      Object.keys(matches).forEach((matchName: string) => {
        const matchScore = matches[matchName].score;
        if (!list[+year][matchName]) {
          list[+year][matchName] = {};
        }
        matchScore.forEach((match) => {
          const sum = +match.a + +match.c + +match.d + +match.miss;
          let typeMatch = '';
          if (sum <= 12) {
            typeMatch = 'short';
            totalCount.short.time.push(+match.time);
            totalCount.short.hf.push(+match.factor);
            yearsSum[+year].short.time.push(+match.time);
            yearsSum[+year].short.hf.push(+match.factor);
          }
          if (sum > 12 && sum <= 24) {
            typeMatch = 'medium';
            totalCount.medium.time.push(+match.time);
            totalCount.medium.hf.push(+match.factor);
            yearsSum[+year].medium.time.push(+match.time);
            yearsSum[+year].medium.hf.push(+match.factor);
          }
          if (sum > 24) {
            typeMatch = 'long';
            totalCount.long.time.push(+match.time);
            totalCount.long.hf.push(+match.factor);
            yearsSum[+year].long.time.push(+match.time);
            yearsSum[+year].long.hf.push(+match.factor);
          }
          list[+year][matchName][match.stage] = {
            type: typeMatch as 'short' | 'medium' | 'long',
            time: match.time,
            hf: match.factor,
          };
        });
      });
    });
  const years: any = {};
  Object.keys(yearsSum).forEach((year: string) => {
    const yearValue = yearsSum[+year];
    years[+year] = {
      short: {
        time: (
          yearValue.short.time.reduce((c: number, a: number) => c + a, 0) /
          yearValue.short.time.length
        ).toFixed(2),
        hf: (
          yearValue.short.hf.reduce((c: number, a: number) => c + a, 0) / yearValue.short.hf.length
        ).toFixed(2),
      },
      medium: {
        time: (
          yearValue.medium.time.reduce((c: number, a: number) => c + a, 0) /
          yearValue.medium.time.length
        ).toFixed(2),
        hf: (
          yearValue.medium.hf.reduce((c: number, a: number) => c + a, 0) /
          yearValue.medium.hf.length
        ).toFixed(2),
      },
      long: {
        time: (
          yearValue.long.time.reduce((c: number, a: number) => c + a, 0) /
          yearValue.long.time.length
        ).toFixed(2),
        hf: (
          yearValue.long.hf.reduce((c: number, a: number) => c + a, 0) / yearValue.long.hf.length
        ).toFixed(2),
      },
    };
  });
  return {
    total: {
      short: {
        time: +(
          totalCount.short.time.reduce((c: number, a: number) => c + a, 0) /
          totalCount.short.time.length
        ).toFixed(2),
        hf: +(
          totalCount.short.hf.reduce((c: number, a: number) => c + a, 0) /
          totalCount.short.hf.length
        ).toFixed(2),
      },
      medium: {
        time: +(
          totalCount.medium.time.reduce((c: number, a: number) => c + a, 0) /
          totalCount.medium.time.length
        ).toFixed(2),
        hf: +(
          totalCount.medium.hf.reduce((c: number, a: number) => c + a, 0) /
          totalCount.medium.hf.length
        ).toFixed(2),
      },
      long: {
        time: +(
          totalCount.long.time.reduce((c: number, a: number) => c + a, 0) /
          totalCount.long.time.length
        ).toFixed(2),
        hf: +(
          totalCount.long.hf.reduce((c: number, a: number) => c + a, 0) / totalCount.long.hf.length
        ).toFixed(2),
      },
    },
    years,
    list,
  };
};
