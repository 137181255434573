import { Global } from '@mantine/core';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Avenir Next Heavy',
            src: 'url(\'/public/AvenirNext-HeavyItalic.woff.ttf\') format("ttf")',
            fontWeight: 900,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
}
