/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { UserMatchesIDPA } from 'pages/api/userMatchesIdpa';
import type { AppState } from '../../store';
import { userMatches } from '../../api/userMatches';

export interface MatchIdpaState {
  raw: any;
}

const initialState: MatchIdpaState = {
  raw: {},
};

export const matchesIdpaSlice = createSlice({
  name: 'matchesIdpa',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userMatches.endpoints.getUserMatchesIdpa.matchFulfilled,
      (state, { payload }) => {
        state.raw = payload;
      }
    );
  },
});

export const selectorPageIdpaRaw = (state: AppState): UserMatchesIDPA => state.matchesIdpa.raw;
export default matchesIdpaSlice.reducer;
