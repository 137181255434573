/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { AppState } from '../store';
import type { UserMatches } from '../../pages/api/userMatches';
import { userMatches } from '../api/userMatches';
import handleTimeHFPageState from '../utils/pageTimeHF';

export type TypeOfMatch = 'short' | 'medium' | 'long';
export type TypeOfMatchStat = 'time' | 'hf';

type Points = {
  alpha: number;
  charlie: number;
  delta: number;
  miss: number;
  no_shoot: number;
  penality: number;
  factor: number;
};

type PrecisionState = {
  total: Points;
  years: Record<number, { percentage: Points }> | [];
};

type NumberState = {
  years: UserMatches;
};

type MatchLengthType = {
  short: Record<TypeOfMatchStat, number>;
  medium: Record<TypeOfMatchStat, number>;
  long: Record<TypeOfMatchStat, number>;
};

export type TimePageStateYears = Record<number, MatchLengthType>;
// {year: {matchName: {stageN: {type:"", time: ""}}}}
export type TimeHFPageStateList = Record<
  number,
  Record<string, Record<string, { type: TypeOfMatch; time: number; hf: number }>>
>;
export type TimeHFPageState = {
  total: MatchLengthType;
  years: TimePageStateYears;
  list: TimeHFPageStateList;
};

export interface MatchState {
  percentage: PrecisionState;
  number: NumberState;
  timeHFPage: TimeHFPageState;
  raw: UserMatches;
}

const initialState: MatchState = {
  percentage: {
    total: {
      alpha: 0,
      charlie: 0,
      delta: 0,
      miss: 0,
      no_shoot: 0,
      penality: 0,
      factor: 0,
    },
    years: [],
  },
  number: {
    years: {},
  },
  timeHFPage: {
    total: {
      short: {
        time: 0,
        hf: 0,
      },
      medium: {
        time: 0,
        hf: 0,
      },
      long: {
        time: 0,
        hf: 0,
      },
    },
    years: {},
    list: {},
  },
  raw: {},
};

export const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userMatches.endpoints.getUserMatches.matchFulfilled,
      (state, { payload }) => {
        state.raw = payload;
        const totalPercentage = {
          alpha: [] as number[],
          charlie: [] as number[],
          delta: [] as number[],
          miss: [] as number[],
          no_shoot: [] as number[],
          penality: [] as number[],
          factor: [] as number[],
        };
        const percentageByYear: Record<string, { percentage: Points }> = {};
        Object.keys(payload)
          .filter((year) => Object.entries(payload[+year]).length > 0)
          .forEach((year: string) => {
            const matches = payload[+year];
            const percentageTmp = {
              alpha: [] as number[],
              charlie: [] as number[],
              delta: [] as number[],
              miss: [] as number[],
              no_shoot: [] as number[],
              penality: [] as number[],
              factor: [] as number[],
            };
            Object.keys(matches).forEach((matchName: string) => {
              if (matches[matchName].sumScore) {
                const sum =
                  matches[matchName].sumScore.alpha +
                  matches[matchName].sumScore.charlie +
                  matches[matchName].sumScore.delta +
                  matches[matchName].sumScore.miss;
                percentageTmp.alpha.push((matches[matchName].sumScore.alpha / sum) * 100);
                percentageTmp.charlie.push((matches[matchName].sumScore.charlie / sum) * 100);
                percentageTmp.delta.push((matches[matchName].sumScore.delta / sum) * 100);
                percentageTmp.miss.push((matches[matchName].sumScore.miss / sum) * 100);
                percentageTmp.no_shoot.push((matches[matchName].sumScore.no_shoot / sum) * 100);
                percentageTmp.penality.push((matches[matchName].sumScore.penality / sum) * 100);
                percentageTmp.factor.push(
                  matches[matchName].sumScore.factor / matches[matchName].score.length
                );
              }
            });
            // @ts-ignore
            percentageByYear[year] = {};
            // @ts-ignore
            percentageByYear[year].percentage = {};
            percentageByYear[year].percentage.alpha = +(
              percentageTmp.alpha.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.alpha.length
            ).toFixed(1);
            percentageByYear[year].percentage.charlie = +(
              percentageTmp.charlie.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.charlie.length
            ).toFixed(1);
            percentageByYear[year].percentage.delta = +(
              percentageTmp.delta.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.delta.length
            ).toFixed(1);
            percentageByYear[year].percentage.miss = +(
              percentageTmp.miss.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.miss.length
            ).toFixed(1);
            percentageByYear[year].percentage.no_shoot = +(
              percentageTmp.no_shoot.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.no_shoot.length
            ).toFixed(1);
            percentageByYear[year].percentage.penality = +(
              percentageTmp.penality.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.penality.length
            ).toFixed(1);
            percentageByYear[year].percentage.factor = +(
              percentageTmp.factor.reduce((p: number, c: number) => +p + +c, 0) /
              percentageTmp.factor.length
            ).toFixed(1);

            totalPercentage.alpha = [...totalPercentage.alpha, ...percentageTmp.alpha];
            totalPercentage.charlie = [...totalPercentage.charlie, ...percentageTmp.charlie];
            totalPercentage.delta = [...totalPercentage.delta, ...percentageTmp.delta];
            totalPercentage.miss = [...totalPercentage.miss, ...percentageTmp.miss];
            totalPercentage.no_shoot = [...totalPercentage.no_shoot, ...percentageTmp.no_shoot];
            totalPercentage.penality = [...totalPercentage.penality, ...percentageTmp.penality];
            totalPercentage.factor = [...totalPercentage.factor, ...percentageTmp.factor];
          });
        state.percentage.total.alpha = +(
          totalPercentage.alpha.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.alpha.length
        ).toFixed(1);
        state.percentage.total.charlie = +(
          totalPercentage.charlie.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.charlie.length
        ).toFixed(1);
        state.percentage.total.delta = +(
          totalPercentage.delta.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.delta.length
        ).toFixed(1);
        state.percentage.total.miss = +(
          totalPercentage.miss.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.miss.length
        ).toFixed(1);
        state.percentage.total.no_shoot = +(
          totalPercentage.no_shoot.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.no_shoot.length
        ).toFixed(1);
        state.percentage.total.penality = +(
          totalPercentage.penality.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.penality.length
        ).toFixed(1);
        state.percentage.total.factor = +(
          totalPercentage.factor.reduce((p: number, c: number) => +p + +c, 0) /
          totalPercentage.factor.length
        ).toFixed(1);
        state.percentage.years = Object.entries(percentageByYear).length ? percentageByYear : [];
        state.number.years = Object.entries(percentageByYear).length ? payload : [];
        state.timeHFPage = handleTimeHFPageState(payload);
      }
    );
  },
});

// export const { setMatches } = matchesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: AppState) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };
export const selectorPrecision = (state: AppState): PrecisionState => state.matches.percentage;
export const selectorNumbers = (state: AppState): NumberState => state.matches.number;
export const selectorPageTime = (state: AppState): TimeHFPageState => state.matches.timeHFPage;
export const selectorPageRaw = (state: AppState): UserMatches => state.matches.raw;
export default matchesSlice.reducer;
