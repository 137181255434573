import { ReactElement, ReactNode } from 'react';
import NextApp, { AppProps, AppContext } from 'next/app';
import Head from 'next/head';
import { MantineProvider, ColorScheme } from '@mantine/core';
import { NextPage } from 'next';
import { Provider } from 'react-redux';
import { Notifications } from '@mantine/notifications';
import { Analytics } from '@vercel/analytics/react';
import store from '../store/store';
import { CustomFonts } from '../components/CustomFonts/CustomFonts';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export default function App(
  props: AppProps & { colorScheme: ColorScheme } & {
    Component: NextPageWithLayout;
  }
) {
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Provider store={store}>
        <Head>
          <title>Saurs - Shooter are your ready? Standby!</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
        </Head>

        <MantineProvider
          theme={{ colorScheme: 'dark', primaryColor: 'red' }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Notifications />
          {getLayout(<Component {...pageProps} />)}
          <CustomFonts />
          <Analytics />
        </MantineProvider>
      </Provider>
    </>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return {
    ...appProps,
  };
};
